import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

/**
 * Returns processed shop logs table
 *
 * @param {Object} table shop logs data
 * @param state
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface, state: any) {
  const uiTable = new UITable(table as any);
  const sorts = Object.values(state.pageSettings.sort) as { sort: string; key: string }[];

  return uiTable
    .removeColumn('type')
    .removeColumn('clientMarketId')

    .setSortableValues(sorts)

    .getTable() as any;
}
