import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './logsFilter';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getShopLogs, getShopLogsTypes } from '@/api/logs';

import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

import { prepareList } from './logsTable';
import ShopLogsFilter from './logsFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { strings } from '@/lib/stringConst';

const MODULE_NAME = 'shopLogs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class ShopLogsModule extends PageBaseModule {
  shopId = '';
  filter: Filter;

  constructor(module: ShopLogsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'shopLogs/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(ShopLogsFilter);
    this.filter.setFilterName('shopLogsFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setBtnClassName('row-5');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SHOP_ID(shopId: string) {
    this.shopId = shopId;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.shopLogsSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init(shopId: string) {
    await this.context.commit('SET_SHOP_ID', shopId);
    this.context.commit('SET_SORT', window.localStorage.shopLogsSort ? JSON.parse(window.localStorage.shopLogsSort) : {});

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    this.initListById(shopId);
  }

  @Action({ rawError: true })
  async initListById(shopId: string) {
    await this.filter.init();
    await this.getListById(shopId);
  }

  @Action({ rawError: true })
  async getListById(shopId: string) {
    const result = await this.requestById(shopId);
    this.setList(result);
  }

  @Action({ rawError: true })
  async requestById(shopId: string) {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = this.filter.filterSettings.filter;

      const result = await getShopLogs(this.pageSettings.pageCurrent, itemsQuery, sort, filter, shopId);

      return result.table;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getFilterForRequest(shopId: string) {
    const filterAdditional = `&filters[0][id]=clientMarketId&filters[0][value]=${shopId}&`;
    this.pageSettings.filterAdditional = filterAdditional;

    return this.pageSettings.filter + filterAdditional;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const tableInfo = prepareList(table, this);
    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async initTypes() {
    const types = await getShopLogsTypes();
    const typesProcessed = Object.values(types as { type: string; humanType: string }[]).map(
      (type: { type: string; humanType: string }) => {
        return {
          id: type.type,
          value: type.humanType,
        };
      }
    );

    return typesProcessed;
  }

  @Action({ rawError: true })
  clearStartDate() {
    this.context.commit('SET_FILTER_START_DATE', 'Выберите');
  }

  @Action({ rawError: true })
  clearEndDate() {
    this.context.commit('SET_FILTER_END_DATE', 'Выберите');
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getListById(this.shopId);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getListById(this.shopId);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    await this.getListById(this.shopId);
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  updateList() {
    this.getListById(this.shopId);
  }
}

export default getModule(ShopLogsModule);
