import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class LogsEmployeesFilter {
  filterHandlers = {
    update: 'shopLogsFilter/updateFilter',
    reset: 'shopLogsFilter/resetFilter',
    resetState: 'shopLogsFilter/resetFilterState',
    removeSelected: 'shopLogsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Дата');
    filter.setTitle('date', '');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'periodStart',
        type: 'date-picker',
        title: 'С ',
        placeholder: '',
        value: '',
        action: 'shopLogsFilter/updateStartDate',
      },
      endDate: {
        key: 'periodEnd',
        type: 'date-picker',
        title: 'По ',
        placeholder: '',
        value: '',
        action: 'shopLogsFilter/updateEndDate',
      },
    });

    filter.createSelectEntity('type');
    filter.setTitle('type', 'По типу события');
    filter.setName('type', 'select_type');
    filter.setPreviewName('type', 'Событие');
    filter.setInitAction('type', 'shopLogs/initTypes');
    filter.setAction('type', 'shopLogsFilter/updateSelect');
    filter.setIconClassName('type', 'icon-lunch');
    filter.setClearAction('type', 'shopLogsFilter/resetSelect');

    this.filterModel = filter.filterModel;
  }
}
