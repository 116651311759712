
import { Component, Vue } from '@/lib/decorator';

import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import PaginationBlock from '@/components/Pagination.vue';
import TitleTab from '../_titleTab.vue';

import ShopEntityModule from '@/store/shops/entity';
import ShopLogsModule from '@/store/shops/logs';
import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    TextDatetime,
    AppTable,
    PaginationBlock,
    AppHeaderMain,
    TitleTab,
  },
  beforeMount() {
    this.shopId = this.$route.params.shopId as string;
  },
})
export default class ShopLogs extends Vue {
  shopId = '';

  get settings(): PageInterface {
    return ShopLogsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return ShopLogsModule.filter.filterSettings;
  }

  get currentTab(): string {
    return ShopEntityModule.tabsNav.logs?.id;
  }

  async resetFilters(): Promise<void> {
    await ShopLogsModule.setPageToBegin();
    await ShopLogsModule.clearSort();
    await ShopLogsModule.filter.resetFilter();
    await ShopLogsModule.getListById(this.shopId);
  }

  async updateList(): Promise<void> {
    await ShopLogsModule.setPageToBegin();
    await ShopLogsModule.filter.updateFilter();
    await ShopLogsModule.getListById(this.shopId);
  }

  sort(header: { id: string; sort: { value: string } }): void {
    ShopLogsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  selectAmount(value: string): void {
    ShopLogsModule.updatePageAmountItems(value);
  }

  async mounted(): Promise<void> {
    await ShopEntityModule.initTitle(this.shopId);
    await ShopLogsModule.init(this.shopId);
  }
}
